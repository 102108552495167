import React, {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

//fonction qui permet de recuperer les casques qui sont en service dans une liste deroulante
function Main(props){
    const {casqueRendu,setCasqueRendu} = props;
    const [Data, setData] = useState([]);

    const handleChange = (event,newvalue) => {
        setCasqueRendu(newvalue);
    }

    //me permet de recuper les casques qui sont en service
    useEffect(() => {
        const fetchData = async () => {
          try {
            let url = "/php/CasqueUse.php";
            if(window.location.hostname === "localhost" || window.location.port === "3000"){
              url = 'http://localhost/php/CasqueUse.php';
            }
            const response = await fetch(url);
            const jsonData = await response.json();
            setData(jsonData);
          } catch (error) {
            console.error('Erreur lors de la récupération des données de la bdd:', error);
          }
        };
      
        fetchData();
      }, []);

      return(
        <Box display={"flex"} flexDirection="column" alignItems={'center'} justifyContent={"center"} key={27} sx={{marginTop:"15px"}}>
          <Box fontSize="small" color={"red"} marginBottom={"10px"}>Le numéro de série est sur la (Fiche/Prise)</Box>
          <Autocomplete
              disablePortal
              id="Casque Rendu"
              value={casqueRendu}
              options={Data}
              getOptionLabel={(option) => option}
              sx={{ width: 300 }}
              onChange={handleChange}
              key={26}
              renderInput={(params) => <TextField {...params} label="Casque Rendu" />}
          />
        </Box>
      )

}   
export default Main;