import { Checkbox, FormControlLabel, Grid, Box} from '@mui/material';
import CasqueR from './CasqueR.js';
// import Tab from './Tab.js';
import Prob from "./Probleme.js";

// Affichage de la page de retour
function Main(props){
    const {casqueRendu,setCasqueRendu,probleme,setProbleme,typeCasque, setTypeCasque,
            naturePanne, setNaturePanne, commentaire, setCommentaire} = props;

    let tab = [];
    if(probleme){
        tab = <Prob typeCasque={typeCasque} setTypeCasque={setTypeCasque} naturePanne={naturePanne} setNaturePanne={setNaturePanne} commentaire={commentaire} setCommentaire={setCommentaire}/>;
    }

    const handleProblemeChange = (event) => {
        if(event.target.checked === false){
            setTypeCasque(0);
            setNaturePanne([false,false,false,false]);
            setCommentaire("");
        }
        setProbleme(event.target.checked);
    };

    return(
        <div>
            <CasqueR casqueRendu={casqueRendu} setCasqueRendu={setCasqueRendu}/>
            <Grid container direction="column" alignItems="center" spacing={2} sx={{marginTop:"10px"}}>

                <Box fontSize="small" color={"red"}>Cocher cette case pour signaler un problème avec le casque.</Box>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={probleme}
                            onChange={handleProblemeChange}
                            value="probleme"
                        />
                    }
                    label="Problème"
                />
            </Grid>
            {probleme && (
                <>
                    {tab}
                </>
            )}
        </div>
    )
}

export default Main;