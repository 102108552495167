import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

//fonction qui permet de recuperer les nom et prenom des utilisateurs dans une liste deroulante
function Main(props) {
    const { setNom_Prenom, Data, setId, setCode} = props;

    const handleChange = (event, newValue) => {
        if (newValue !== null) {
            setNom_Prenom(newValue.nomPrenom);
            setId(newValue.id);
            setCode(newValue.code);
        } else {
            setNom_Prenom('');
            setId(null);
            setCode('');
        }
    }

    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Autocomplete
                disablePortal
                id="Nom_Prenom"
                options={Data}
                getOptionLabel={(option) => option.nomPrenom}
                sx={{ width: 300 }}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label="Nom Prenom" />}
            />
        </Box>
    );
}

export default Main;
