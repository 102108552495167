import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Grid } from "@mui/material";


function Tab(props) {
    const { checked, setChecked, checkbox } = props;

    //permet de recuperer les checkbox selectionner
    const handleChange = (event, index) => {
        var newchecked = [...checked];
        newchecked[index] = event.target.checked;
        setChecked(newchecked);
    };

    //permet de diviser le tableau en deux pour afficher les checkbox sur deux colonnes pour un meilleur visuel sur telephone
    const halfLength = Math.ceil(checkbox.length / 2);
    const firstColumn = checkbox.slice(0, halfLength);
    const secondColumn = checkbox.slice(halfLength);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={2}>
                {firstColumn.map((label, index) => (
                    <Box
                        key={index}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <FormControlLabel
                            key={checkbox[index] + index}
                            control={
                                <Checkbox
                                    key={checkbox[index] + index}
                                    checked={checked[index]}
                                    onChange={(event) =>
                                        handleChange(event, index)
                                    }
                                    value={checkbox[index]}
                                />
                            }
                            label={label}
                        />
                    </Box>
                ))}
            </Grid>
            <Grid item sm={4}>
                {secondColumn.map((label, index) => (
                    <Box
                        key={index + halfLength}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <FormControlLabel
                            key={checkbox[index + halfLength] + index}
                            control={
                                <Checkbox
                                    key={
                                        checkbox[index + halfLength] + index
                                    }
                                    checked={checked[index + halfLength]}
                                    onChange={(event) =>
                                        handleChange(event, index + halfLength)
                                    }
                                    value={checkbox[index + halfLength]}
                                />
                            }
                            label={label}
                        />
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default Tab;