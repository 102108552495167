import React from "react";
import CasqueR from "./CasqueR.js";
import CasqueP from "./CasqueP.js";
import Prob from "./Probleme.js";
import {Typography, Checkbox, FormControlLabel, Grid, Box } from '@mui/material';

// Affichage de la page d'echange
function main(props){
    const {CasqueRendu, setCasqueRendu, casqueDote, setCasqueDote,typeCasque, setTypeCasque, naturePanne, setNaturePanne, commentaire, setCommentaire, probleme, setProbleme} = props;

    const tab = <Prob typeCasque={typeCasque} setTypeCasque={setTypeCasque} naturePanne={naturePanne} setNaturePanne={setNaturePanne} commentaire={commentaire} setCommentaire={setCommentaire}/>;
    
    const handleProblemeChange = (event) => {
        if(event.target.checked === false){
            setTypeCasque(0);
            setNaturePanne([false,false,false,false]);
            setCommentaire("");
        }
        setProbleme(event.target.checked);
    };

    return (
        <div key={18}>
            <Typography variant="h6" gutterBottom component="div" key={1}sx={{fontWeight: 'bold', marginBottom: "5px"}}>
                Référence du casque rendu : 
            </Typography>
            <CasqueR CasqueRendu={CasqueRendu} setCasqueRendu={setCasqueRendu}/>

            <Grid container direction="column" alignItems="center" spacing={2} sx={{marginTop:"20px"}}>
                
                <Box fontSize="small" color={"red"}>Cocher cette case pour signaler un problème avec le casque.</Box>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={probleme}
                            onChange={handleProblemeChange}
                            value="probleme"
                        />
                    }
                    label="Problème"
                />
            </Grid>

            {probleme && (
                <>
                    {tab}
                </>
            )}

            <Typography variant="h6" gutterBottom component="div" key={8} sx={{fontWeight: 'bold', marginTop: "10px"}}>
                Référence du casque pris :
            </Typography>
            <CasqueP casqueDote={casqueDote} setCasqueDote={setCasqueDote}/>
        </div>
    );
}

export default main;


