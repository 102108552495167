import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//fonction qui permet de recuperer la raison de l'utilisation de l'application web pour savoir quoi afficher
function main(props){
    const {raison,setRaison ,handleReset} = props;

    //permet de recuperer la raison selectionner et le mettre dans la variable raison
    const handleChange = (event) => {
        setRaison(event.target.value);
        handleReset();
    }

    return (
        <Box display={"flex"} justifyContent={"center"} sx={{marginTop: "15px",marginBottom:"10px"}}>
            <FormControl >
                <InputLabel id="raison">Raison du retour</InputLabel>
                <Select
                    labelId="raison"
                    id="raison"
                    value={raison}
                    label="Raison"
                    onChange={handleChange}
                    style={{ width: 160 }}
                >
                    <MenuItem value={1}>Echange</MenuItem>
                    <MenuItem value={2}>Dotation</MenuItem>
                    <MenuItem value={3}>Perte</MenuItem>
                    <MenuItem value={4}>Retour</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

}

export default main;