import { useEffect } from 'react';

function MainPage() {
    useEffect(() => {
        const disableBack = (event) => {
            event.preventDefault();
            window.history.backward(); // Redirige l'utilisateur vers l'avant
        };

        // Empêcher le retour en arrière
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBack);

        return () => {
            window.removeEventListener('popstate', disableBack);
        };
    }, []);

    return (
        <div className="main-page-container">
            <div className="main-page-content">
                <h1>Page de fin</h1>
                <p>Votre saisie a été prise en compte.</p>
                <p>Vous pouvez fermer la fenêtre</p>
            </div>
        </div>
    );
}

export default MainPage;
