import './App.css';
import Dev from './Affichage/dev.js';
import Co from './Connexion/Connexion.js';
import LastPage from './LastPage.js';
import Historique from './Historique/Histo.js';
import Problemes from './Problemes/Probleme.js';
import { useState } from 'react';

function App() {
  const [connected, setConnected] = useState(0);

  let componentToDisplay;
  let url = "/php"
  if (connected === 0) {
    componentToDisplay = <Co setConnected={setConnected} />;
  } else if (connected === 1) {
    componentToDisplay = <Dev />;
  } else if (connected === 2) {
    if (window.location.hostname === "localhost") {
      url = 'http://localhost:80/php/';
    }
    window.location.href = url;
  }

  // Condition pour afficher LastPage
  if (window.location.pathname === '/LastPage') {
    componentToDisplay = <LastPage />;
  }
  else if(window.location.pathname === '/Historique'){
    componentToDisplay = <Historique />;
  }
  else if(window.location.pathname === '/Problemes'){
    componentToDisplay = <Problemes />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <title>Casque</title>
      </header>
      {/* Affichage conditionnel du composant */}
      {componentToDisplay}
    </div>
  );
}

export default App;