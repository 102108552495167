import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';

//fonction qui permet un affichage des données de l'historique de la bdd dans un tableau
//Abandonné car impossible d'y acceder depuis le site malgré le fait qu'en local cela marche 
function Tab({ data }) {

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'date', headerName: 'Date', width: 180 },
    { field: 'nom_pre', headerName: 'Nom et Prénom', width: 200 },
    { field: 'id_perso', headerName: 'ID Personne', width: 150 },
    { field: 'ref_casque_rendu', headerName: 'Ref. Casque Rendu', width: 180 },
    { field: 'ref_casque_pris', headerName: 'Ref. Casque Pris', width: 180 },
    { field: 'raison', headerName: 'Raison', width: 150 },
  ];

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredRows = data.filter((row) =>
      columns.some(
        (column) =>
          row[column.field]
            .toString()
            .toLowerCase()
            .indexOf(keyword) > -1
      )
    );
    setFilteredData(filteredRows);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div>
        <input
        type="text"
        placeholder="Recherche..."
        onChange={handleSearch}
        style={{ marginBottom: 20 }}
        />
        
        <div style={{ width: "100%", height: "800px"}}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            disableExtendRowFullWidth
            disableFooter
            rowsPerPageOptions={[]}
            pagination={false}
            hideFooter={true}
          />
        </div>
      </div>
    </div>
  );
}

function Main() {
    const [data, setData] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          let url = "/php/histoData.php";
          if (window.location.hostname === "localhost" || window.location.port === "3000") {
            url = 'http://localhost/php/histoData.php';
          }
          const response = await fetch(url);
          const jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error('Erreur lors de la récupération des données de la bdd:', error);
        }
      };
  
      fetchData();
    }, []);
  
    return (
      <div style={{ height: 400, width: '100%' }}>
        <h1>Historique</h1>
        <Tab data={data} />
      </div>
    );
  }
  

export default Main;
