import CasqueP from "./CasqueP.js";

// Affichage de la page de dotation
function Main(props){
    const {casqueDote,setCasqueDote} = props;

    return(
        <div>
            <CasqueP casqueDote={casqueDote} setCasqueDote={setCasqueDote}/>
        </div>
    )
}

export default Main;