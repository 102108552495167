import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';

//fonction qui permet un affichage des données des Problemes de la bdd dans un tableau
//Abandonné car impossible d'y acceder depuis le site malgré le fait qu'en local cela marche 
function Tab({ data }) {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
    if (data.length > 0) {
      const filteredRows = data.filter((row) =>
        Object.values(row).some(
          (value) =>
            value.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
      );
      setFilteredData(filteredRows);
    } else {
      setFilteredData(data);
    }
  }, [data, searchTerm]);

  const columns = [
      { field: 'id', headerName: 'ID', width: 10 },
      { field: 'ref', headerName: 'Réference', width: 100 },
      { field: 'prise', headerName: 'Prise', width: 60 },
      { field: 'cordon', headerName: 'Cordon', width: 80 },
      { field: 'arceau', headerName: 'Arceau', width: 80 },
      { field: 'oreillette_d', headerName: 'Oreillette Droite', width: 110 },
      { field: 'oreillette_g', headerName: 'Oreillette Gauche', width: 110 },
      { field: 'perche', headerName: 'Perche', width: 80 },
      { field: 'micro', headerName: 'Micro', width: 80 },
      { field: 'alternat', headerName: 'Alternat', width: 80 },
      { field: 'autre', headerName: 'Autre', width: 80 },
      { field: 'emission', headerName: 'Emission', width: 80 },
      { field: 'permanente_e', headerName: 'Permanente émission', width: 100 },
      { field: 'intermittente_e', headerName: 'Intermittente émission', width: 100 },
      { field: 'reception', headerName: 'Réception', width: 100 },
      { field: 'permanent_r', headerName: 'Permanente Réception', width: 100 },
      { field: 'intermittente_r', headerName: 'Intermittente Réception', width: 100 },
      { field: 'commentaire', headerName: 'Commentaire', width: 150 },
    ];
    const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div>
        <input
          type="text"
          placeholder="Recherche..."
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: 20 }}
        />
        
        <div style={{ width: "100%"}}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            disableExtendRowFullWidth
            disableFooter
          />
        </div>
      </div>
    </div>
  );
}

function Main() {
    const [data, setData] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          let url = "/php/PrblmData.php";
          if (window.location.hostname === "localhost" || window.location.port === "3000") {
            url = 'http://localhost/php/PrblmData.php';
          }
          const response = await fetch(url);
          const jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error('Erreur lors de la récupération des données de la bdd:', error);
        }
      };
  
      fetchData();
    }, []);
  
    return (
      <div style={{ height: 400, width: '100%' }}>
        <h1>Problemes</h1>
        <Tab data={data} />
      </div>
    );
  }
  

export default Main;
